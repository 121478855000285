
				import { hydrate } from "svelte";
				import { createContext } from "/src/contexts/createContext.ts";
				import Component from "/src/templates/Homepage.svelte";
				window.addEventListener("load", () => {
					const props = JSON.parse(document.querySelector("#props").innerText);
					const globalSettings = JSON.parse(document.querySelector("#global-settings").innerText);
					const context = createContext(props, globalSettings);
					hydrate(Component, { target: document.body, props, context });
				});
			