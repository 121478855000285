<script lang="ts">
	import LinkCard from "@components/LinkCard.svelte";
	import Icon from "@components/common/Icon.svelte";
	import Link from "@components/common/Link.svelte";
	import { ButtonVariant } from "@components/common/getButtonClass.js";
	import arrowNext from "@core/assets/icons/arrow-next.svg?raw";
	import { getClientsPath } from "@core/schema/paths/getClientsPath.js";
	import { getConnectWithFastestPath } from "@core/schema/paths/getConnectWithFastestPath.js";
	import { getGlobalTemplateProps } from "../contexts/getGlobalTemplateProps.js";
	import { getIndustries } from "../data/getIndustries.js";

	const { tenant } = getGlobalTemplateProps();
	const industries = getIndustries(tenant);
</script>

{#if industries.filter((industry) => industry.show === true).length > 1}
	<div class="grid-container max-sm:full-width grow">
		<section class="pb-24 xl:pb-28">
			<div>
				<div class="max-w-[75rem] grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-[1.03rem] mb-12 mx-auto">
					{#each industries as { title, iconName, href, show }, index (index)}
						{#if show}
							<LinkCard {title} {iconName} {href} />
						{/if}
					{/each}
				</div>
				<div class="flex flex-col gap-3">
					<Link
						variant={ButtonVariant.Phlox}
						class="mx-auto flex items-center justify-center"
						href={getConnectWithFastestPath(tenant)}
						>Chci fastest služby
						<Icon icon={arrowNext} class="ml-2 text-fstgrey-900"></Icon>
					</Link>
					<Link
						variant={ButtonVariant.GreenOutlineLightBg}
						class="mx-auto flex items-center justify-center"
						href={getClientsPath(tenant)}>Komu jsme pomohli</Link
					>
				</div>
			</div>
		</section>
	</div>
{/if}
