import type { ServiceCardItem } from "@components/ServiceCardItem.js";
import car from "@core/assets/icons/car.svg?raw";
import cloudCircle from "@core/assets/icons/cloud-circle.svg?raw";
import deliverySpeed from "@core/assets/icons/delivery-speed.svg?raw";
import factory from "@core/assets/icons/factory.svg?raw";
import gastro from "@core/assets/icons/gastro.svg?raw";
import hr from "@core/assets/icons/hr.svg?raw";
import retail from "@core/assets/icons/retail.svg?raw";
import shoppingCart from "@core/assets/icons/shopping-cart.svg?raw";
import stavebnictvi from "@core/assets/icons/stavebnictvi.svg?raw";
import ucetnictvi from "@core/assets/icons/ucetnictvi.svg?raw";
import type { Tenant } from "@core/schema/Tenant.js";
import { getEcommercePath } from "@core/schema/paths/getEcommercePath.js";

export function getIndustries(tenant: Tenant): ServiceCardItem<true>[] {
	return [
		{
			title: "E-commerce",
			iconName: shoppingCart,
			href: getEcommercePath(tenant),
			show: true,
		},
		{
			title: "Gastronomie a hotely",
			iconName: gastro,
			href: "/#",
			show: false,
		},
		{
			title: "Logistika",
			iconName: deliverySpeed,
			href: "/#",
			show: false,
		},
		{
			title: "XaaS",
			iconName: cloudCircle,
			href: "/#",
			show: false,
		},
		{
			title: "Výroba",
			iconName: factory,
			href: "/#",
			show: false,
		},
		{
			title: "HR",
			iconName: hr,
			href: "/#",
			show: false,
		},
		{
			title: "Účetnictví",
			iconName: ucetnictvi,
			href: "/#",
			show: false,
		},
		{
			title: "Automotive",
			iconName: car,
			href: "/#",
			show: false,
		},
		{
			title: "Stavebnictví",
			iconName: stavebnictvi,
			href: "/#",
			show: false,
		},
		{
			title: "Retail",
			iconName: retail,
			href: "/#",
			show: false,
		},
	];
}
