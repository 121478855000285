export function tween({
	from,
	to,
	duration,
	easing = (t): number => t,
	onUpdate,
	onComplete,
}: {
	from: number;
	to: number;
	duration: number;
	easing?(this: void, t: number): number;
	onUpdate?(this: void, value: number): void;
	onComplete?(this: void): void;
}): void {
	const startTime = performance.now();

	function animate(currentTime: number): void {
		const elapsedTime = currentTime - startTime;
		const progress = Math.min(elapsedTime / duration, 1);
		const easedProgress = easing(progress);
		const currentValue = from + (to - from) * easedProgress;

		if (onUpdate) {
			onUpdate(currentValue);
		}

		if (elapsedTime < duration) {
			requestAnimationFrame(animate);
		} else {
			if (onComplete) {
				onComplete();
			}
		}
	}

	requestAnimationFrame(animate);
}
