<script lang="ts">
	import heroLg from "@assets/images/sladkadilna-hero.png?webp&h=700&imagetools";
	import heroXl from "@assets/images/sladkadilna-hero.png?webp&h=950&imagetools";
	import { Background } from "@components/Background.js";
	import BookMeeting from "@components/BookMeeting.svelte";
	import FoldingCards from "@components/FoldingCards.svelte";
	import HighlightedProjects from "@components/HighlightedProjects.svelte";
	import Industries from "@components/Industries.svelte";
	import LogoReferences from "@components/LogoReferences.svelte";
	import Section from "@components/Section.svelte";
	import ServicesCards from "@components/ServicesCards.svelte";
	import TechnologyCards from "@components/TechnologyCards.svelte";
	import TrustCards from "@components/TrustCards.svelte";
	import Formatted from "@components/common/Formatted.svelte";
	import Icon from "@components/common/Icon.svelte";
	import Link from "@components/common/Link.svelte";
	import Media from "@components/common/Media.svelte";
	import { ButtonVariant } from "@components/common/getButtonClass.js";
	import Layout from "@components/layout/Layout.svelte";
	import arrowNext from "@core/assets/icons/arrow-next.svg?raw";
	import { getConnectWithFastestPath } from "@core/schema/paths/getConnectWithFastestPath.js";
	import { getGlobalTemplateProps } from "../contexts/getGlobalTemplateProps.js";
	import { BookMeetingPerson, getBookMeetingPeople } from "../data/getBookMeetingPeople.js";
	import { getLogoPanelsBasic } from "../data/getLogoPanelsBasic.js";
	import { getReasonsForCoop } from "../data/getReasonsForCoop.js";
	import { getServices } from "../data/getServices.js";
	import { getTechnologies } from "../data/getTechnologies.js";
	import type { HomepageProps } from "./HomepageProps.js";

	const { tenant } = getGlobalTemplateProps();
	const _: HomepageProps = $props();
</script>

<Layout>
	<div class="overflow-clip full-width grid-container">
		<section class="items-center bg-gradient-to-b from-fstlight-100 to-white full-width grid-container">
			<div
				class="container-1 container-end-12 lg:container-start-1 xl:container-start-2 lg:container-end-6 md:max-w-lg xl:max-w-3xl flex flex-col gap-6 z-10 py-12 lg:py-28"
			>
				<h1>
					<Formatted text="Pomocí softwaru na míru cg[[zrychlíme]] růst vašeho podnikání" />
				</h1>
				<p class="md:max-w-lg">
					<Formatted
						text="Na krabicová řešení si nepotrpíme. Vyvíjíme webové aplikace i další IT řešení podle potřeb klientů. nl[[sp[[Bez serverů a efektivně!]]]]"
					/>
				</p>
				<Link
					variant={ButtonVariant.Phlox}
					class="flex items-center justify-center mx-auto lg:mx-0"
					href={getConnectWithFastestPath(tenant)}
				>
					Chci fastest služby
					<Icon icon={arrowNext} class="ml-2 text-fstgrey-900" />
				</Link>
			</div>
			<div
				class="container-start-7 container-end-12 relative w-full h-full hidden lg:block pointer-events-none select-none"
			>
				<div class="absolute -left-[50%] -right-[70%] -top-[35%] -bottom-[30%]">
					<Media
						class="absolute inset-0 h-full w-full object-contain"
						source={{ src: heroXl, type: "image/webp" }}
						width={1280}
						height={961}
						alt="Ukázka realizovaného projektu Sladká dílna"
						eager
						sourcesets={{
							"<xl": heroLg,
							">xl": heroXl,
						}}
					/>
				</div>
			</div>
		</section>

		<TrustCards />
	</div>

	<Section centered gridHeadline="container-6" noPadding="bot">
		{#snippet headline()}
			<Formatted text="S čím vám mimo jiné cg[[pomůžeme]]" />
		{/snippet}
	</Section>
	<ServicesCards cards={getServices(tenant)} />

	<Section centered gridHeadline="container-8" noPadding="bot">
		{#snippet headline()}
			<Formatted text="Naše cg[[nejúspěšnější]] projekty za poslední dobu" />
		{/snippet}
	</Section>
	<HighlightedProjects />

	<Section
		gridHeadline="lg:container-start-2 lg:container-end-5"
		gridChildren="lg:container-start-7 lg:container-end-11"
		background={Background.LightGradient}
		paddingFollowNext
		class="items-end"
	>
		{#snippet headline()}
			<Formatted text="Vývoj pomocí cg[[ověřených]] technologií a na cloudu" />
		{/snippet}
		<p>
			<Formatted
				text="Vytváření prémiového softwaru vyžaduje používání moderních nástrojů. Proto pracujeme s ověřenými a dlouhodobě podporovanými technologiemi, které nabízí ideální poměr cena/výkon."
			/>
		</p>
		<p>
			<Formatted
				text="Vyvíjíme primárně na sp[[cloudu AWS (Amazon Web Services)]], což znamená, že vám sp[[ušetříme až desítky tisíc Kč]] za dedikované servery."
			/>
		</p>
	</Section>
	<TechnologyCards technologies={getTechnologies(tenant)} />
	<Link
		variant={ButtonVariant.Phlox}
		class="mx-auto flex items-center justify-center mt-8 lg:mt-12"
		href={getConnectWithFastestPath(tenant)}
		>Chci fastest služby
		<Icon icon={arrowNext} class="ml-2 text-fstgrey-900"></Icon>
	</Link>

	<Section background={Background.LightGradient} centered gridHeadline="container-6" noPadding="bot">
		{#snippet headline()}
			<Formatted text="cg[[Komu]] jsme cg[[zrychlili]] rozvoj podnikání" />
		{/snippet}
	</Section>
	<LogoReferences logos={getLogoPanelsBasic(tenant)} />
	<Link
		variant={ButtonVariant.Phlox}
		class="mx-auto flex items-center justify-center mt-8 lg:mt-12"
		href={getConnectWithFastestPath(tenant)}
		>Chci fastest služby
		<Icon icon={arrowNext} class="ml-2 text-fstgrey-900"></Icon>
	</Link>

	<Section centered gridHeadline="container-8" gridDesc="container-6" paddingFollowNext>
		{#snippet headline()}
			<Formatted text="IT řešení na míru pro firmy od cg[[e-commerce]] po cg[[logistiku]]" />
		{/snippet}
		{#snippet desc()}
			<p>
				<Formatted
					text="Díky individuálnímu přístupu a schopnosti jít nad rámec vývoje pracujeme na zakázkách z různých odvětví. sp[[Nejčastěji pomáháme e-shopům, logistickým firmám, restauracím nebo výrobním podnikům]]."
				/>
			</p>
		{/snippet}
	</Section>
	<Industries />

	<Section
		background={Background.Onyx}
		gridHeadline="container-6 lg:container-start-1 lg:container-end-5 xl:container-start-2 xl:container-end-4 max-lg:text-center"
		gridDesc="lg:container-start-7 lg:container-end-12 xl:container-start-6 xl:container-end-11"
	>
		{#snippet headline()}
			<Formatted text="cg[[Proč]] si plácnout s cg[[Fastest Solution]]" />
		{/snippet}
		{#snippet desc()}
			<FoldingCards color={Background.Onyx} cards={getReasonsForCoop(tenant)} />
		{/snippet}
		<div class="container-12">
			<Link
				variant={ButtonVariant.Phlox}
				class="mx-auto flex items-center justify-center mt-8 lg:mt-12"
				href={getConnectWithFastestPath(tenant)}
				>Chci fastest služby
				<Icon icon={arrowNext} class="ml-2 text-fstgrey-900"></Icon>
			</Link>
		</div>
	</Section>

	{@const contacts = getBookMeetingPeople(tenant)}
	<BookMeeting contacts={[contacts[BookMeetingPerson.Tyson], contacts[BookMeetingPerson.Kucera]]} />
</Layout>
