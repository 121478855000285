<script lang="ts">
	import { inViewport } from "@utils/inViewport.js";
	import { tween } from "@utils/tween.js";
	import { getGlobalTemplateProps } from "../contexts/getGlobalTemplateProps.js";
	import { fastestYearsInBusiness } from "../data/fastestFounded.js";
	import { getTeamMembers } from "../data/getTeamMembers.js";

	const { tenant } = getGlobalTemplateProps();

	const commonClasses = "flex flex-col px-6 lg:px-10 py-6 lg:py-[3.125rem] bg-white border-fstlight-100";
	const numberClasses = "text-fstgreen text-xl lg:text-2xl font-black font-loos-wide -tracking-[0.01em]";
	let yearsInBusiness = $state(fastestYearsInBusiness);
	let finishedProjects = $state(330);
	let tailorMade = $state(100);
	let teamSize = $state(getTeamMembers(tenant).filter((member) => member.show).length);
	let started = $state(false);
	const duration = 3000;

	function startTweening(): void {
		if (started) {
			return;
		}
		started = true;
		tween({
			from: 0,
			to: yearsInBusiness,
			duration,
			easing: (t) => 1 - (1 - t) ** 5,
			onUpdate: (value) => (yearsInBusiness = Math.round(value)),
		});
		tween({
			from: 100,
			to: finishedProjects,
			duration,
			easing: (t) => 1 - (1 - t) ** 5,
			onUpdate: (value) => (finishedProjects = Math.round(value)),
		});
		tween({
			from: 50,
			to: tailorMade,
			duration,
			easing: (t) => 1 - (1 - t) ** 5,
			onUpdate: (value) => (tailorMade = Math.round(value)),
		});
		tween({
			from: 1,
			to: teamSize,
			duration,
			easing: (t) => 1 - (1 - t) ** 5,
			onUpdate: (value) => (teamSize = Math.round(value)),
		});
	}
</script>

<div class="full-width md:grid-container bg-white lg:border-t-4 border-fstlight-100">
	<section
		class="grid grid-cols-2 lg:grid-cols-4"
		use:inViewport={{
			intersectChange(isIntersecting: boolean): void {
				if (isIntersecting) {
					startTweening();
				}
			},
			rootMargin: "",
		}}
	>
		<div class={[commonClasses, "border-r-4 border-l-4 max-lg:border-t-4 max-lg:border-b-4"]}>
			<span class={numberClasses}>{yearsInBusiness}</span>
			<span class="text-md font-medium text-fstonyx">let na trhu</span>
		</div>
		<div class={[commonClasses, "border-r-4 max-lg:border-r-4 max-lg:border-t-4 max-lg:border-b-4"]}>
			<span class={numberClasses}>{finishedProjects}+</span>
			<span class="text-md font-medium text-fstonyx">projektů</span>
		</div>
		<div class={[commonClasses, "border-r-4 max-lg:border-l-4"]}>
			<span class={numberClasses}>{tailorMade}%</span>
			<span class="text-md font-medium text-fstonyx">na míru</span>
		</div>
		<div class={[commonClasses, "border-r-4 border-fstlight-100"]}>
			<span class={numberClasses}>{teamSize}+</span>
			<span class="text-md font-medium text-fstonyx">specialistů</span>
		</div>
	</section>
</div>
