import type { ActionReturn } from "svelte/action";

interface InViewportOptions {
	intersectChange?(isIntersecting: boolean): void;
	rootMargin?: string;
}

export function inViewport(element: HTMLElement, options?: InViewportOptions): ActionReturn {
	const observer = new IntersectionObserver(
		(entries) => {
			const [entry] = entries;
			const isIntersecting = entry?.isIntersecting ?? false;
			options?.intersectChange?.(isIntersecting);
		},
		{
			rootMargin: options?.rootMargin ?? `-150px 0px -150px 0px`,
		},
	);

	observer.observe(element);

	return {
		destroy(): void {
			observer.disconnect();
		},
	};
}
